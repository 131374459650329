import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MachineCard = _resolveComponent("MachineCard")!
  const _component_MachineActivity = _resolveComponent("MachineActivity")!
  const _component_MachineTimeline = _resolveComponent("MachineTimeline")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.machine.id)
      ? (_openBlock(), _createBlock(_component_MachineCard, {
          key: 0,
          machine: _ctx.machine,
          type: "training"
        }, null, 8, ["machine"]))
      : _createCommentVNode("", true),
    (_ctx.machine.id)
      ? (_openBlock(), _createBlock(_component_MachineActivity, {
          key: 1,
          machine: _ctx.machine
        }, null, 8, ["machine"]))
      : _createCommentVNode("", true),
    (_ctx.machine.id)
      ? (_openBlock(), _createBlock(_component_MachineTimeline, {
          key: 2,
          machine: _ctx.machine
        }, null, 8, ["machine"]))
      : _createCommentVNode("", true)
  ]))
}